import React, { Fragment } from "react"
import { Link } from "gatsby"
import RSTags from "./tags"


const ItemPanel = ({ post, includeTags }) => {

  const glyph = post.frontmatter.glyph ?? "cloud-fill"

  const tagPayload = includeTags ? (<p className="my-0"><RSTags tagList={post.frontmatter.tags}/></p>) : ""
  return (
    <div class="col py-1 px-2">
      <div class="card h-100 shadow-sm">
        <div class="row g-0 h-100 p-0">
          <div class="col-3 g-0 p-0 m-0 px-auto h-100 align-self-center" >
            <div class="row index-icon bg-gradient align-self-center h-100 g-0 rounded-start">
              <div className="text-center text-white align-self-center">
                <i className={"bi bi-" + glyph} style={{ fontSize: "3em" }}></i>
              </div>
            </div>
          </div>
          <div class="col-9 h-100">
            <div class="row align-self-center h-100 g-0">
              <div className="align-self-center">
              <div class="card-body p-0 py-3 px-2 m-0">

                <h3 class="card-title h5">
                  <Link to={post.frontmatter.slug} class="stretched-link text-decoration-none text-body" activeClassName="stretched-link text-decoration-none text-body">
                    {post.frontmatter.title}        </Link>
                </h3>
                <p class="card-text">{post.frontmatter.excerpt}</p>
                {tagPayload}
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    </div >
  )
}
export default ItemPanel


