import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "./layout";
import ContentList from "./contentlist";
import { TitleBar } from "./titlebar";
import Panels from "./panels";
const IndexPage = (props, location) => {
  return (
    <StaticQuery
      query={graphql`
          query PageIndexQuery {
            allMdx(
                filter: {frontmatter: {slug: {regex: "/.*/"}}}
                sort: {fields: [frontmatter___priority, frontmatter___title], order: DESC}
            ) {
              edges {
                node {
                  id
                  frontmatter {
                    slug
                    title
                    excerpt
                    glyph
                    tags
                  }
                }
              }
            }
          }
        `}
      render={data => {
        const selectedNodes = data.allMdx.edges.filter((edge) => edge.node.frontmatter.slug.startsWith(props.startPath))
        // const selectedNodes = data.allMdx.edges
        const list = Panels(
          selectedNodes, props.includeTags)
        return (
          <React.Fragment>
            <div className="container">
              <div className="mt-3">{props.blurb}</div>
              {list}</div>
          </React.Fragment>
        )
      }}
    />
  )
}

export default IndexPage