import * as React from 'react'

import { Link } from 'gatsby'
import ConditionalBreadcrumb from './conditionalbreadcrumb'

export const TitleBar = ({ title, crumbs, containerClasses }) => {
  return (
    <div class="text-white  pt-3 pad-safe-lr mt-0 titlebar">
      <div class="container">
        <div class="row">
          <ConditionalBreadcrumb crumbs={crumbs} />
          <h1 class="fw-bold" >{title}</h1>
        </div>
      </div>
    </div>

  )
}
// Step 3: Export your component
