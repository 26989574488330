import * as React from 'react'
import { Link } from 'gatsby'

const ConditionalBreadcrumb = ({ crumbs }) => {

  const crumbLabelOverrides=
    {
      '/services':"Services",
      '/experience':'Experience',
      '/principles': 'Principles'
    }


  function breadcrumb(crumbsToInclude) {
    return (
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          {crumbsToInclude.map((crumb)=>(<li class="breadcrumb-item"><Link className="text-white" to={crumb.pathname}>{crumb.pathname in crumbLabelOverrides? crumbLabelOverrides[crumb.pathname]:crumb.crumbLabel}</Link></li>))}
        </ol>
      </nav>
    )
  }

  const activeCrumbs = crumbs.crumbs.filter(crumb=>crumb.pathname!==crumbs.location).filter(crumb=>crumb.pathname!=="/about")


  return breadcrumb(activeCrumbs)
}
// Step 3: Export your component

export default ConditionalBreadcrumb