import React from "react"
import IndexPage from "../components/index-page";
import Seo from "../components/seo";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { TitleBar } from "../components/titlebar";
const PrinciplePage = ({ location, data, pageContext }) => {

  const blurb = (<React.Fragment><p class="lead">Principles sounds a bit grand, but we feel it's useful to outline how we work, and how we work with clients.</p></React.Fragment>)
  const title = "Our Principles"
  const slug = "/principles"

  return (<React.Fragment>
    <Header containerClasses="container" />
    <Seo title={title} pathname={slug} description={title} />
    <TitleBar title={title} location={location} crumbs={pageContext.breadcrumb}/>
    <IndexPage title={title} startPath="/principles/" blurb={blurb} location={location}/>
    <Footer containerClasses="container" />

  </React.Fragment>)

}
// Step 3: Export your component
export default PrinciplePage