import * as React from 'react'
import { Link } from 'gatsby'

const RSTags = ({ tagList }) => {
  if (tagList.length===0) {
    return (<React.Fragment />)
  }
  return (<React.Fragment>
    
    {tagList.map((tag)=>{return(<span style={{marginRight:"0.333rem"}} class="badge rounded-pill bg-light text-secondary text-uppercase">{tag}</span>
  )})}</React.Fragment>)
}
// Step 3: Export your component

export default RSTags