import React from "react"
import ItemPanel from "./item-panel";
import '../assets/css/features.css'

export default function Panels(edges, includeTags) {
  const Posts = edges
    .map(edge => <ItemPanel key={edge.node.id} post={edge.node} includeTags={includeTags}/>);

  return (
      <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 gx-4 gy-2 mb-2 services">
        {Posts}
      </div>
  );
}
